import {
  getInsuranceCompanies,
  getSinglePropertyTransaction,
  setDamagesCover,
} from "@services/krent.service";
import { formatCurrency } from "@utils/helpers";
import React, { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";

type InsuranceOption = {
  agency: string;
  standardFee: number;
  premiumFee: number;
  id: string;
};

type InsuranceCompany = {
  name: string;
  id: string;
  standardFee?: number;
  premiumFee?: number;
};

interface DamagesCoverProps {
  insuranceCompanies: string;
  showDamagesCover: boolean;
  setShowDamagesCover: (value: boolean) => void;
  transactionId: string;
  setTransaction?: (transaction: any) => void;
  cancelBooking?: any;
  transaction?: any;
}

export const DamagesCover: React.FC<DamagesCoverProps> = ({
  insuranceCompanies,
  showDamagesCover,
  setShowDamagesCover,
  transactionId,
  setTransaction,
  cancelBooking,
  transaction,
}) => {
  const [selectedFeeType, setSelectedFeeType] = useState<string>("");
  const [selectedInsurance, setSelectedInsurance] =
    useState<InsuranceOption | null>(null);
  const [insuranceOptions, setInsuranceOptions] = useState<InsuranceOption[]>(
    []
  );
  const [isMobile, setIsMobile] = useState(false);
  const [showTooltip, setShowTooltip] = useState({
    cautionFee: false,
    insurance: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const fetchInsuranceCompanies = async () => {
    try {
      const res = (await getInsuranceCompanies()) as {
        data: InsuranceCompany[];
      };

      const filteredCompanies = res.data
        .filter((company) => insuranceCompanies?.includes(company.id))
        .map((company) => ({
          id: company.id,
          agency: company.name,
          standardFee: company.standardFee || 0,
          premiumFee: company.premiumFee || 0,
        }));

      setInsuranceOptions(filteredCompanies);
    } catch (error) {
      console.error("Failed to fetch insurance companies", error);
      setInsuranceOptions([]);
    }
  };

  useEffect(() => {
    if (insuranceCompanies && insuranceCompanies.length > 0) {
      fetchInsuranceCompanies();
    }
  }, [insuranceCompanies]);

  const handleYesClick = () => {
    setShowDamagesCover(false);
    setShowDamagesCover(true);
  };

  const handleFeeTypeSelect = (type: string) => {
    setSelectedFeeType(type);
    setShowTooltip({ cautionFee: false, insurance: false });
  };

  const handleInsuranceSelect = (insurance: InsuranceOption) => {
    setSelectedInsurance(insurance);
  };

  const handleTooltipClick = (
    e: React.MouseEvent,
    type: "cautionFee" | "insurance"
  ) => {
    e.stopPropagation();
    if (isMobile) {
      setShowTooltip((prev) => ({
        cautionFee: type === "cautionFee" ? !prev.cautionFee : false,
        insurance: type === "insurance" ? !prev.insurance : false,
      }));
    }
  };

  const handleSubmitDamagesCover = async () => {
    setIsSubmitting(true);
    try {
      const payload =
        selectedFeeType === "insurance" && selectedInsurance
          ? {
              damagesCoverageOption: "insurance",
              insuranceCompanyId: selectedInsurance.id,
            }
          : {
              damagesCoverageOption: "cautionFee",
            };

      await setDamagesCover(transactionId, payload);
      toast.success("Selected successfully!");

      setShowDamagesCover(false);
      const abortController = new AbortController();

      await new Promise((resolve) => setTimeout(resolve, 1000));

      try {
        const response = await getSinglePropertyTransaction(
          transactionId,
          abortController.signal
        );
        if ((response as any)?.data && setTransaction) {
          setTransaction((response as any)?.data);
        }
      } catch (error) {
        console.error("Failed to refetch transaction:", error);
      }
    } catch (error) {
      console.error("Failed to set damages cover", error);
      toast.error((error as any)?.errors[0]?.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBackClick = () => {
    setSelectedInsurance(null);
    setSelectedFeeType("");
  };
  const TooltipContent = ({ type }: { type: "cautionFee" | "insurance" }) => (
    <div className="p-3">
      {type === "cautionFee" ? (
        <>
          <h6 className="fw-bold">Caution fees </h6>
          <p className="mb-0">
            Krent collect a caution fee to provide renters with protection
            against damages to the property's amenities. This fee acts as a
            security deposit that covers any potential repairs or replacements
            needed for damages caused during the rental period. It ensures that
            both the property and the amenities are maintained in good
            condition. If no damages occur, the caution fee is fully refunded at
            the end of your lease, offering peace of mind while protecting the
            property throughout your stay. Krent has a policy of investing
            caution fee as it stays on the platform. Krent would disburse the
            profit per year to you.
          </p>
        </>
      ) : (
        <>
          <h6 className="fw-bold">Renting insurance</h6>
          <p className="mb-0">
            Krent partners with insurance agencies to provide renters with
            protection against damages to the property's amenities. This
            insurance helps ensure that in case of any accidental damage to
            fixtures, appliances, or other features in the home, the costs for
            repairs or replacements are covered. As part of the leasing process,
            we ask renters to complete an amenities checklist to ensure all
            property features are in good condition before moving in. This
            checklist serves as a detailed record of the state of the amenities,
            which helps both renters and the property owner understand what is
            covered.
          </p>
        </>
      )}
    </div>
  );

  const renderTooltip = (type: "cautionFee" | "insurance") => {
    if (isMobile) {
      return (
        <>
          <button
            className="btn btn-link p-2"
            onClick={(e) => handleTooltipClick(e, type)}
          >
            <i className="fa-regular fa-circle-info text-info"></i>
          </button>
          {showTooltip[type] && (
            <div className="mobile-tooltip">
              <TooltipContent type={type} />
            </div>
          )}
        </>
      );
    }

    return (
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="right"
        overlay={
          <Tooltip id={`${type}-tooltip`} className="tooltip-wide">
            <TooltipContent type={type} />
          </Tooltip>
        }
      >
        <button className="btn btn-link p-2">
          <i className="fa-regular fa-circle-info text-info"></i>
        </button>
      </OverlayTrigger>
    );
  };

  return (
    <>
      <div className="mt-3">
        <p>Would you like to proceed with the transaction?</p>

        <div className="d-flex gap-2 mt-3">
          <button
            className="rounded-10 btn btn-primary w-100 fw-bold"
            onClick={handleYesClick}
          >
            Yes
          </button>
          <button
            className="btn border-green text-primary w-100 fw-bold rounded-10"
            onClick={cancelBooking}
          >
            No
          </button>
        </div>
      </div>

      <Modal
        show={showDamagesCover}
        // onClose={() =>{
        //   setSelectedInsurance(null)
        //   setSelectedFeeType("")
        //   setShowDamagesCover(false)}}
        centered
        dialogClassName="damages-cover-modal"
      >
        <div className="container p-4">
          <div className="d-flex justify-content-between">
            <h5 className="fw-bold">
              {selectedFeeType === "insurance" ? (
                <>
                  <button
                    className="btn btn-link p-0 me-2"
                    onClick={handleBackClick}
                  >
                    <i className="fa-regular fa-arrow-left-to-line"></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;
                </>
              ) : (
                ""
              )}
              <i className="fa-regular fa-house-crack"></i>&nbsp;
              {selectedFeeType === "insurance"
                ? "Renting Insurance Fees"
                : "Select damages cover"}
            </h5>
            <button
              className="bg-white border-0"
              onClick={() => {
                setSelectedInsurance(null);
                setSelectedFeeType("");
                setShowDamagesCover(false);
              }}
            >
              <i className="fa-regular fa-x"></i>
            </button>
          </div>
          <p className="fs-16 mt-2">
            Selecting one of this would insure the property and amenities.
          </p>

          {selectedFeeType !== "insurance" && (
            <div className="mt-4">
              <div
                className={`option-container ${
                  selectedFeeType === "cautionFee" ? "border-primary" : ""
                }`}
                onClick={() => handleFeeTypeSelect("cautionFee")}
              >
                <div className="d-flex align-items-center gap-2">
                  <input
                    type="radio"
                    checked={selectedFeeType === "cautionFee"}
                    onChange={() => {}}
                  />
                  <span>
                    Caution fees{" "}
                    {selectedFeeType === "cautionFee" &&
                      "-" +
                        transaction?.listing?.currency +
                        formatCurrency(transaction?.listing?.cautionFee)}
                  </span>
                </div>
                {renderTooltip("cautionFee")}
              </div>

              <div
                className={`option-container mt-3 ${
                  selectedFeeType === "insurance" ? "border-primary" : ""
                }`}
                onClick={() => handleFeeTypeSelect("insurance")}
              >
                <div className="d-flex align-items-center gap-2">
                  <input
                    type="radio"
                    checked={selectedFeeType === "insurance"}
                    onChange={() => {}}
                  />
                  <span>Renting insurance fee</span>
                </div>
                {renderTooltip("insurance")}
              </div>
            </div>
          )}

          {selectedFeeType === "insurance" && (
            <div className="mt-4 table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Select one</th>
                    <th>Agency</th>
                    <th>Standard fee/YR</th>
                    <th>Premium fee/YR</th>
                  </tr>
                </thead>
                <tbody>
                  {insuranceOptions.map((option, index) => (
                    <tr
                      key={index}
                      className="cursor-pointer"
                      onClick={() => handleInsuranceSelect(option)}
                    >
                      <td>
                        <input
                          type="radio"
                          name="insurance"
                          checked={selectedInsurance === option}
                          onChange={() => {}}
                        />
                      </td>
                      <td>{option.agency}</td>
                      <td>{option.standardFee.toLocaleString()}</td>
                      <td>{option.premiumFee.toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <button
            className="btn btn-primary w-100 rounded-pill mt-4"
            onClick={handleSubmitDamagesCover}
            disabled={
              isSubmitting ||
              (selectedFeeType === "insurance"
                ? !selectedInsurance
                : !selectedFeeType)
            }
          >
            {isSubmitting ? (
              <span>
                <i className="fa-regular fa-spinner fa-spin me-2"></i>
                Processing...
              </span>
            ) : (
              "Continue"
            )}
          </button>
        </div>
      </Modal>
    </>
  );
};
