import React, { useState, ChangeEvent, useEffect } from "react";
import SuccessfulCreation from "@pages/Developers/Projects/success-modal";
import { getSinglePropertyTransaction, sendAmenitiesChecklist } from "@services/krent.service";

interface AmenityCategory {
  title: string;
  offers: string[];
}

interface AmenityItem {
  id: number;
  name: string;
  category: string;
  units: number;
  checked: boolean;
  comment: string;
}

interface Props {
  transactionId: string;
  amenities: AmenityCategory[];
  setOpenValidateAmenities: (open: boolean) => void;
  closeModal?: any;
  setTransaction?: any;
}

const AmenitiesChecklist: React.FC<Props> = ({
  transactionId,
  amenities,
  setOpenValidateAmenities,
  closeModal,
  setTransaction
}) => {
  const [successModal, setSuccessModal] = useState(false);
  const createInitialAmenities = (): AmenityItem[] => {
    let id = 1;
    return amenities?.flatMap((category) =>
      category.offers.map((offer) => ({
        id: id++,
        name: offer,
        category: category.title,
        units: 0,
        checked: false,
        comment: "",
      }))
    );
  };

  const [amenityItems, setAmenityItems] = useState<AmenityItem[]>(
    createInitialAmenities()
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (successModal) {
      timer = setTimeout(() => {
        setSuccessModal(false);
      }, 20000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [successModal]);

  const handleUnitsChange = (id: number, increment: number): void => {
    setAmenityItems((items) =>
      items.map((item) =>
        item.id === id
          ? { ...item, units: Math.max(0, item.units + increment) }
          : item
      )
    );
  };

  const handleCommentChange = (id: number, comment: string): void => {
    setAmenityItems((items) =>
      items.map((item) => (item.id === id ? { ...item, comment } : item))
    );
  };

  const handleCheckChange = (id: number): void => {
    setAmenityItems((items) =>
      items.map((item) =>
        item.id === id ? { ...item, checked: !item.checked } : item
      )
    );
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setError("");

    try {
      const payload = amenityItems.map((item) => ({
        name: item.name.toLowerCase(),
        category: item.category,
        number: item.units,
        comments: item.comment,
        checked: item.checked,
      }));
     await sendAmenitiesChecklist(transactionId, payload);
      setOpenValidateAmenities(false);
      setSuccessModal(true);
      const abortController = new AbortController()
      
      await new Promise(resolve => setTimeout(resolve, 1000))
      
      try {
        const response = await getSinglePropertyTransaction(
          transactionId,
          abortController.signal
        )
        if (response as any) {
          setTransaction((response as any)?.data) 
        }
      } catch (error) {
        console.error('Failed to refetch transaction:', error)
      }
      
    } catch (err: any) {
      setError(err.message || "Failed to submit checklist. Please try again.");
      console.error("Submission error:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const filteredAmenities = amenityItems?.filter(
    (item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.category.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mt-4">
      <div className="card mx-auto" style={{ maxWidth: "1000px" }}>
        <div className="card-body">
          <div className="mb-4 position-relative">
            <div
              className="position-absolute"
              style={{
                left: "15px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <svg
                className="text-secondary"
                style={{ width: "16px", height: "16px" }}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
            <input
              type="text"
              placeholder="Search amenities or categories"
              className="form-control ps-5"
              value={searchTerm}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setSearchTerm(e.target.value)
              }
            />
          </div>

          {error && <div className="alert alert-danger">{error}</div>}

          <div className="table-responsive">
            <table className="table table-borderless">
              <thead>
                <tr className="fw-medium">
                  <th style={{ width: "8%" }}>S/N</th>
                  <th style={{ width: "8%" }}>Check</th>
                  <th style={{ width: "20%" }}>Amenity</th>
                  <th style={{ width: "20%" }}>Units</th>
                  <th style={{ width: "24%" }}>Leave a comment</th>
                </tr>
              </thead>
              <tbody>
                {filteredAmenities?.map((item) => (
                  <tr key={item.id} className="border-top">
                    <td>{item.id}</td>
                    <td>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={item.checked}
                          onChange={() => handleCheckChange(item.id)}
                        />
                      </div>
                    </td>
                    <td>{item.name}</td>
                    <td>
                      <div
                        className="input-group"
                        style={{ maxWidth: "150px" }}
                      >
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          onClick={() => handleUnitsChange(item.id, -1)}
                        >
                          -
                        </button>
                        <span
                          className="input-group-text bg-white"
                          style={{ width: "50px" }}
                        >
                          {item.units}
                        </span>
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          onClick={() => handleUnitsChange(item.id, 1)}
                        >
                          +
                        </button>
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type message here..."
                        value={item.comment}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleCommentChange(item.id, e.target.value)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex gap-2 mt-3">
            <button
              className="btn btn-primary w-100 fw-bold"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? "SUBMITTING..." : "SUBMIT CHECKLIST"}
            </button>
            <button
              className="btn border-green text-primary w-100 fw-bold rounded-10"
              onClick={closeModal}
              disabled={isSubmitting}
            >
              DO THIS LATER
            </button>
          </div>
        </div>
      </div>
      <SuccessfulCreation
        setSuccessModal={setSuccessModal}
        successModal={successModal}
        title={"Amenities checklist submitted!"}
        content="You have successfully submitted the amenities checklist for this property"
        buttonText="Go back to messaging"
        buttonLink={`/message/${transactionId}`}
      />
    </div>
  );
};

export default AmenitiesChecklist;
